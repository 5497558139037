import { useWorkspace } from '~/stores/workspace'

export default defineNuxtRouteMiddleware(async (from, to) => {
  const { $datadog } = useNuxtApp()

  if (!process.client) {
    return
  }

  const workspace = useWorkspace()

  if (
    !hasWorkspacePermission(workspace.userPermissions, workspace.workspaceId, [
      WORKSPACE_PERMISSION.writeSystem,
      WORKSPACE_PERMISSION.readSystem,
    ])
  ) {
    $datadog.addAction('Access system admin - DENIED', {
      workspace_id: workspace.workspaceId,
      workspace_name: workspace.activeWorkspace?.name,
    })

    if (from.name === to.name) {
      return navigateTo({ path: '/', query: to.query }, { replace: true })
    }

    return abortNavigation()
  }
})
